<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    serial?: string
    category: string
    thumbnailUrl: string
    urlSlug: string
    priceType: string
    priceNormal: number
    priceDiscount: number
    totalRating: number
    totalUserRegistered: number
    tagNames: Array<string> | null
    userProgress?: number | string
    isReviewed?: boolean
  }>(),
  {
    title: 'title',
    serial: null,
    category: 'category',
    thumbnailUrl: 'url',
    urlSlug: 'slug',
    priceType: 'PAID',
    priceNormal: 0,
    priceDiscount: 0,
    totalRating: 0,
    totalUserRegistered: 0,
    tagNames: () => [],
    userProgress: null,
    isReviewed: false
  }
)
const { getCertificate } = useGenerateCertificate()
const { addCdnToUrl } = useFormatUrlCdn()
const { serial, urlSlug } = toRefs(props)
const router = useRouter()
const emits = defineEmits(['onReview'])

const { isMobile } = useDevice()

const startLearning = () => {
  router.push({ query: null }).then(() => {
    router.replace({
      path: `/kursus/${urlSlug.value}/materi`,
      query: {
        courseSerial: serial.value
      }
    })
  })
}
</script>

<template>
  <div class="kg-card-course">
    <NuxtLink
      :to="`/kursus/${urlSlug}`"
      target="_blank"
      @click.prevent="$router.push(`/kursus/${urlSlug}`)"
    >
      <div class="kg-card-course__image">
        <nuxt-img
          v-if="thumbnailUrl"
          :src="addCdnToUrl(thumbnailUrl)"
          alt="course-image"
          loading="lazy"
          size="sm:100%"
          format="webp"
          :quality="isMobile ? '50' : '80'"
          preload
        />
      </div>

      <h3 class="kg-card-course__title">
        {{ title }}
      </h3>

      <p class="kg-card-course__price">
        Rp
        {{
          $currencyFormat(priceDiscount > 0 ? priceDiscount : priceNormal || 0)
        }}
        <span v-if="priceNormal && priceNormal > 0 && priceDiscount > 0">
          Rp {{ $currencyFormat(priceNormal ?? 0) }}
        </span>
      </p>

      <div class="kg-card-course__info">
        <star-filled class="info-icon-star" /> {{ totalRating }} |
        <user-outlined class="info-icon-user" />
        {{ totalUserRegistered }} Terdaftar
      </div>

      <div v-if="tagNames?.length" class="kg-card-course__tags">
        <a-tag v-for="(tag, idx) in tagNames" :key="idx" color="#F2F4F5">
          {{ tag }}
        </a-tag>
      </div>
    </NuxtLink>
    <div
      v-if="userProgress !== null && +userProgress >= 0"
      class="kg-card-course__progress"
    >
      <a-button
        v-if="+userProgress === 0"
        type="primary"
        size="large"
        block
        @click="startLearning"
      >
        Mulai Belajar
      </a-button>
      <a-button
        v-else-if="isReviewed && +userProgress === 100"
        size="large"
        block
        @click="getCertificate({ serial })"
      >
        Download Sertifikat
      </a-button>
      <template v-else-if="+userProgress === 100">
        <a-button
          v-if="!isReviewed"
          type="primary"
          block
          @click="emits('onReview')"
        >
          Berikan Rating
        </a-button>
        <a-button v-else size="large" block @click="getCertificate({ serial })">
          Download Sertifikat
        </a-button>
      </template>
      <AtomsProgress v-else :from="+userProgress" :to="100" />
    </div>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
